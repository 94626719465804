import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import {
  isBrowser,
  permissionsHasOneOf,
  hasPermission
} from '../components/Auth'

import { getLoggedUser } from '../services/api/get-logged-user'
import { getRanks } from '../services/api/get-ranks'
import { getOrganigrams } from '../services/api/organigrams'
import { getForces } from '../services/api/forces'
import { Context } from '../Context'

export default function ProfilePage () {
  const { isAuth, loggedUser } = useContext(Context)

  if (isBrowser() && !isAuth) {
    window.location.href = '/'

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const [rank, setRank] = useState({ name: '' })
  const [force, setForce] = useState({ name: '' })
  const [organigram, setOrganigram] = useState({ name: '' })

  // carga valores necesarios
  useEffect(() => {
    // carga la fuerza
    if (loggedUser.force_id) {
      getForces({ id: loggedUser.force_id })
        .then(res => {
          setForce(res.data[0])
        })
        .catch(err => {
          console.error(err)
        })
    }

    // carga el rango
    if (loggedUser.rank_id) {
      getRanks({ id: loggedUser.rank_id })
        .then(res => {
          setRank(res.data[0])
        })
        .catch(err => {
          console.error(err)
        })
    }

    // carga el organigrama
    if (loggedUser.organigram_id) {
      getOrganigrams({
        id: loggedUser.organigram_id
      })
        .then(res => {
          if (res.data) {
            setOrganigram(res.data[0])
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [loggedUser])

  return (
    <Layout className='page-profile'>
      <div
        style={{
          maxWidth: '600px',
          margin: '3rem auto'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              textTransform: 'uppercase',
              color: 'white'
            }}
          >
            {loggedUser.name} {loggedUser.name2} {loggedUser.lastname} {loggedUser.lastname2}
          </div>

          <Link
            to='/edit-profile/'
            style={{
              fontSize: '0.7rem',
              backgroundColor: 'white',
              display: 'inline-flex',
              alignItems: 'center',
              padding: '0 0.5rem',
              color: 'green',
              fontWeight: 'bold'
            }}
          >
            EDITAR
          </Link>
        </div>

        <div
          style={{
            color: 'white',
            textTransform: 'uppercase',
            borderBottom: '1px solid white',
            paddingBottom: '1rem',
            marginBottom: '1rem'
          }}
        >
          <div
            style={{
              margin: '1rem 0'
            }}
          >
            {loggedUser.email}
          </div>
          <div
            style={{
              margin: '1rem 0'
            }}
          >
            {loggedUser.phone}
          </div>
          <div>
            Fuerza: {force.name ? force.name : 'Ninguna'}
          </div>
          <div>Rango: {rank.name ? rank.name : 'Ninguno'}</div>
          <div>{organigram.name}</div>
        </div>

        <div style={{ margin: '1rem 0' }}>
          <Link
            to='/password/'
            style={{
              color: 'white',
              border: '1px solid white',
              padding: '0.25rem',
              marginRight: '20px'
            }}
          >
          Cambiar contraseña
          </Link>
        </div>

        {/*
          read_all_remission (id=99);
          read_force_remission (id=100);
          read_remission_as_receptor (id=109)
        */}
        {!permissionsHasOneOf([99, 100, 109]) ? null : (
          <div style={{ margin: '1rem 0' }}>
            <Link
              to='/remissions/'
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '0.25rem'
              }}
            >
              Remisiones
            </Link>
          </div>
        )}

        {/* create_invitation (id=116) */}
        {hasPermission(116) && (
          <div style={{ margin: '1rem 0' }}>
            <Link
              to='/invitation-list/'
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '0.25rem'
              }}
            >
            Invitaciones
            </Link>
          </div>
        )}

        {/* read_report (id=123) */}
        {hasPermission(123) && (
          <div style={{ margin: '1rem 0' }}>
            <Link
              to='/reports/'
              style={{
                color: 'white',
                border: '1px solid white',
                padding: '0.25rem'
              }}
            >
              Reportes
            </Link>
          </div>
        )}
      </div>
    </Layout>
  )
}
